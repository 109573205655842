import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/design/design/node_modules/@primer/gatsby-theme-doctocat/src/components/layout.js";
import { DoDontContainer, Do, Dont, Caption } from '@primer/gatsby-theme-doctocat';
import ScrollToAnchor from '~/src/components/scroll-to-anchor';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <ScrollToAnchor mdxType="ScrollToAnchor" />
    <h2>{`Principles`}</h2>
    <h3>{`Reasonable defaults, easy overrides`}</h3>
    <p>{`Optimize for what most people will need to do most of the time, but make it easy for people to adjust it to their needs. Often this means considering the default behavior of each command, and how it might need to be adjusted with flags.`}</p>
    <h3>{`Make it feel like GitHub`}</h3>
    <p>{`Using this tool, it should be obvious that it’s GitHub and not anything else. Use details that are specific to GitHub, such as language or color. When designing output, reflect the GitHub.com interface as much as possible and appropriate.`}</p>
    <DoDontContainer mdxType="DoDontContainer">
  <Do mdxType="Do">
    <img alt="" src="https://user-images.githubusercontent.com/980622/215497434-62e03bc6-8b64-4f08-8d0d-c931def35c03.png" width="100%" />
    <Caption mdxType="Caption">Use language accurate to GitHub.com</Caption>
  </Do>
  <Dont mdxType="Dont">
    <img alt="" src="https://user-images.githubusercontent.com/980622/215497426-4c1d30df-91df-4e42-a293-ad590768f20d.png" width="100%" />
    <Caption mdxType="Caption">Don't use language that GitHub.com doesn't use</Caption>
  </Dont>
    </DoDontContainer>
    <DoDontContainer mdxType="DoDontContainer">
  <Do mdxType="Do">
    <img alt="" src="https://user-images.githubusercontent.com/980622/215497433-e050966e-a102-4d50-93c3-bfa6d7251560.png" width="100%" />
    <Caption mdxType="Caption">Use sentence case</Caption>
  </Do>
  <Dont mdxType="Dont">
    <img alt="" src="https://user-images.githubusercontent.com/980622/215497423-4eeea07a-7a2e-4af7-a486-c9a773c29454.png" width="100%" />
    <Caption mdxType="Caption">Don't use title case</Caption>
  </Dont>
    </DoDontContainer>
    <h4>{`Resources`}</h4>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://brand.github.com"
        }}>{`GitHub Brand Content Guide`}</a></li>
    </ul>
    <h3>{`Reduce cognitive load`}</h3>
    <p>{`Command line interfaces are not as visually intuitive as graphical interfaces. They have very few affordances (indicators of use), rely on memory, and are often unforgiving of mistakes. We do our best to design our commands to mitigate this.`}</p>
    <p>{`Reducing cognitive load is necessary for `}<a parentName="p" {...{
        "href": "https://www.w3.org/TR/coga-usable/#summary"
      }}>{`making an accessible product`}</a>{` .`}</p>
    <h4>{`Ways to reduce cognitive load`}</h4>
    <ul>
      <li parentName="ul">{`Include confirm steps, especially for riskier commands`}</li>
      <li parentName="ul">{`Include headers to help set context for output`}</li>
      <li parentName="ul">{`Ensure consistent command language to make memorizing easier`}</li>
      <li parentName="ul">{`Ensure similar commands are visually and behaviorally parallel. `}{`*`}{` For example, any create command should behave the same`}</li>
      <li parentName="ul">{`Anticipate what people might want to do next. `}{`*`}{` For example, we ask if you want to delete your branch after you merge.`}</li>
      <li parentName="ul">{`Anticipate what mistakes people might make`}</li>
    </ul>
    <h3>{`Bias towards terminal, but make it easy to get to the browser`}</h3>
    <p>{`We want to help people stay in the terminal wherever they might want to maintain focus and reduce context switching, but when it’s necessary to jump to GitHub.com make it obvious, fast, and easy. Certain actions are probably better to do in a visual interface.`}</p>
    <img src="https://user-images.githubusercontent.com/980622/215497436-cccc800d-4f68-4a40-8a93-3f6b5ae741b2.png" alt="A prompt asking 'What's next?' with the choice 'Preview in browser' selected." />
    <Caption mdxType="Caption">A preview in browser step helps users create issues and pull requests more smoothly.</Caption>
    <img src="https://user-images.githubusercontent.com/980622/215497438-3b664837-0182-4d94-9433-f9a59ad642ff.png" alt="The gh pr create command with title and body flags outputting a pull request URL." />
    <Caption mdxType="Caption">Many commands output the relevant URL at the end.</Caption>
    <img src="https://user-images.githubusercontent.com/980622/215497440-5d1f2ebb-b8b8-4dc6-8104-68c125dd85d0.png" alt="The gh issue view command with the --web flag. The output is opening a URL in the browser." />
    <Caption mdxType="Caption">Web flags help users jump to the browser quickly</Caption>
    <h2>{`Process`}</h2>
    <p>{`When designing for the command line, consider:`}</p>
    <h3>{`1. What the command does`}</h3>
    <ul>
      <li parentName="ul">{`What makes sense to do from a terminal? What doesn’t?`}</li>
      <li parentName="ul">{`What might people want to automate?`}</li>
      <li parentName="ul">{`What is the default behavior? What flags might you need to change that behavior?`}</li>
      <li parentName="ul">{`What might people try and fail to do and how can you anticipate that?`}</li>
    </ul>
    <h3>{`2. What the command is called`}</h3>
    <ul>
      <li parentName="ul">{`What should the `}<a parentName="li" {...{
          "href": "/native/cli/foundations#language"
        }}>{`command language system`}</a>{` be?`}</li>
      <li parentName="ul">{`What should be a command vs a flag?`}</li>
      <li parentName="ul">{`How can you align the language of the new command with the existing commands?`}</li>
    </ul>
    <h3>{`3. What the command outputs`}</h3>
    <ul>
      <li parentName="ul">{`What can you do to make the CLI version `}<a parentName="li" {...{
          "href": "/native/cli/getting-started#make-it-feel-like-github"
        }}>{`feel like the GitHub.com version`}</a>{`, using `}<a parentName="li" {...{
          "href": "/native/cli/foundations#color"
        }}>{`color`}</a>{`, `}<a parentName="li" {...{
          "href": "/native/cli/foundations#language"
        }}>{`language`}</a>{`, `}<a parentName="li" {...{
          "href": "/native/cli/foundations#spacing"
        }}>{`spacing`}</a>{`, info shown, etc?`}</li>
      <li parentName="ul">{`How should the `}<a parentName="li" {...{
          "href": "/native/cli/foundations#scriptability"
        }}>{`machine output`}</a>{` differ from the interactive behavior?`}</li>
    </ul>
    <h3>{`4. How you explain your command`}</h3>
    <ul>
      <li parentName="ul">{`You will need to provide a short and long description of the command for the `}<a parentName="li" {...{
          "href": "/components/help"
        }}>{`help pages`}</a>{`.`}</li>
    </ul>
    <h3>{`5. How people discover your command`}</h3>
    <ul>
      <li parentName="ul">{`Are there ways to integrate CLI into the feature where it exists on other platforms?`}</li>
    </ul>
    <h2>{`Prototyping`}</h2>
    <p>{`When designing for GitHub CLI, there are several ways you can go about prototyping your ideas.`}</p>
    <h3>{`Google Docs`}</h3>
    <img src="https://user-images.githubusercontent.com/980622/215497389-fc902fb0-e0c0-40f7-a745-d6469af09936.png" alt="A screenshot of the Google Docs template" />
    <p>{`Best for simple quick illustrations of most ideas`}</p>
    <p>{`Use `}<a parentName="p" {...{
        "href": "https://docs.google.com/document/d/1JIRErIUuJ6fTgabiFYfCH3x91pyHuytbfa0QLnTfXKM/edit?usp=sharing"
      }}>{`this template`}</a>{`, or format your document with these steps:`}</p>
    <ol>
      <li parentName="ol">{`Choose a dark background (File > Page Setup > Page Color)`}</li>
      <li parentName="ol">{`Choose a light text color`}</li>
      <li parentName="ol">{`Choose a monospace font`}</li>
    </ol>
    <h4>{`Tips`}</h4>
    <ul>
      <li parentName="ul">{`Mix it up since people’s setups change so much. Not everyone uses dark background!`}</li>
      <li parentName="ul">{`Make use of the document outline and headers to help communicate your ideas`}</li>
    </ul>
    <h3>{`Figma`}</h3>
    <img src="https://user-images.githubusercontent.com/980622/215497367-3d6febe7-3565-4ff1-9725-fc7b6eaa25a3.png" alt="A screenshot of the Figma library" />
    <p>{`If you need to show a process unfolding over time, or need to show a prototype that feels more real to users, Figma or code prototypes are best.`}</p>
    <p><a parentName="p" {...{
        "href": "https://www.figma.com/file/zYsBk5KFoMlovE4g2f4Wkg/Primer-Command-Line"
      }}><strong parentName="a">{`Figma library`}</strong></a>{` (accessible to GitHub staff only)`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      